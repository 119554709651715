@import 'app/app.variables.less';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';


/*  PRINT  */
.pagebreak {
    page-break-before: always;
    margin-left: 10px;
    margin-top: 20px;
}

@font-face {
    font-family: Antonio-Light;
    src: url(assets/fonts/Antonio-Light.ttf);
}

@font-face {
    font-family: Antonio-Regular;
    src: url(assets/fonts/Antonio-Regular.ttf);
}

@font-face {
    font-family: Brandon_bld_it;
    src: url(assets/fonts/Brandon_bld_it.otf);
}

@font-face {
    font-family: Brandon_bld;
    src: url(assets/fonts/Brandon_bld.otf);
}

@font-face {
    font-family: Brandon_blk_it;
    src: url(assets/fonts/Brandon_blk_it.otf);
}

@font-face {
    font-family: Brandon_blk;
    src: url(assets/fonts/Brandon_blk.otf);
}

@font-face {
    font-family: Brandon_light_it;
    src: url(assets/fonts/Brandon_light_it.otf);
}

@font-face {
    font-family: Brandon_light;
    src: url(assets/fonts/Brandon_light.otf);
}

@font-face {
    font-family: Brandon_med_it;
    src: url(assets/fonts/Brandon_med_it.otf);
}

@font-face {
    font-family: Brandon_med;
    src: url(assets/fonts/Brandon_bld.otf);
}

@font-face {
    font-family: Brandon_reg_it;
    src: url(assets/fonts/Brandon_reg_it.otf);
}

@font-face {
    font-family: Brandon_reg;
    src: url(assets/fonts/Brandon_reg.otf);
}

@font-face {
    font-family: Brandon_thin_it;
    src: url(assets/fonts/Brandon_thin_it.otf);
}

@font-face {
    font-family: Brandon_thin;
    src: url(assets/fonts/Brandon_thin.otf);
}

html {
    font-size: 14px;
}

.layout-wrapper .topbar {
    .layout-main .layout-content {
        padding: 2em 3em;
    }

    .topbar-right {
        #ayuda-button {
            position: absolute;
            height: 1.5em;
            right: 24px;
            top: unset;
            color: white;
            font-size: 20pt;
            vertical-align: middle;

            float: right;
            transition: all 0.3s;
        }

        .logo {
            width: 50%;
            height: auto;
            background: transparent;
            display: block;
            margin: 0 auto;
            position: relative;
            text-align: center;

            img {
                width: auto;
                margin: 0 auto;
                height: 50px;
                align-self: center;
            }

            .title {
                color: white;
                font-weight: bold;
                font-size: 14px;
                overflow: hidden;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: 0.2s all ease-in-out;
            }

            &:hover {
                cursor: pointer;

                .title {
                    font-weight: bold;
                }
            }
        }

        .buttons {
            width: 10em;
            color: @primary-color-text;
        }
    }
}

.layout-wrapper .topbar .topbar-right #menu-button {
    left: 16px;
    position: absolute;
    top: 19px;
}

@media screen and (max-width: 40em) {
    .layout-wrapper .topbar {
        .topbar-right {
            height: auto;

            .logo {
                width: 75%;
                height: 100%;
                position: relative;
                margin: 0;
                margin-right: 2em;
                margin-left: auto;

                img {
                    height: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

body {
    
    *[hidden] {
        display: none !important;
    }
    h4 {
        color: @main-dark;
        padding-left: 2rem;
    }

    input {
        &.mat-input-element {
            font-size: 0.9rem;
        }
    }

    .botonera {
        .p-button {
            background-color: @accent1;
        }
    }

    p-card .p-card .p-card-title {
        font-size: 1.25rem;
    }

    .titulo-precio.p-card {
        height: 100%;

        .p-card-body .p-card-title {
            font-size: 1rem;
            text-transform: uppercase;
        }
    }

    .p-panel {
        border-radius: 10px;

        .p-panel-header {
            border-radius: 10px 10px 0 0;

            padding: 1.5rem;

            span {
                &.p-panel-title,
                &.titulo-panel {
                    font-size: @h3-section-font-size;
                    font-weight: @h3-section-font-weight;
                    color: @h3-section-font-color;
                    font-family: @h-font;
                    text-transform: uppercase;
                }

                &.titulo-panel {
                    margin-right: 1rem;
                    vertical-align: super;
                }
            }
        }

        .p-panel-content {
            padding: 0 1.5rem 1rem 1.5rem;
            border-radius: 0 0 10px 10px;
        }
    }

    .hidden {
        display: none;
    }

    .print-only {
        visibility: hidden;
    }

    i.disabled {
        cursor: auto;
        color: @disabled;
        opacity: 0.9;
    }

    .title-tabs {
        .tab {
            border: 1px solid @main-border-light;
            border-bottom: 0;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            text-align: center;
            max-height: 3em;
            margin-left: -1em;
            background-color: @main-background;
            z-index: 0;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &.active {
                background-color: white;
                z-index: 1;

                border-left: 3px solid #b7b7b7;
                border-right: 3px solid #b7b7b7;

                &.first {
                    border-left: 1px solid @main-border-light;
                }

                &:last-child {
                    border-right: 1px solid @main-border-light;
                }
            }
        }
    }

    .danger {
        color: @danger;
    }

    .warning {
        color: @warning;
    }

    .success {
        color: @success;
    }

    .model-gestor {
        .button-wrapper {
            display: flex;
            //align-items: center;
            justify-content: center;

            button {
                width: 100%;
                height: 3.25em;
                margin-top: 3px;
            }
        }
    }

    .filtro-content {
        .mat-form-field-appearance-outline .mat-form-field-wrapper {
            margin-bottom: -1.25em;
        }

        .button-wrapper {
            padding: 0.5em;

            button {
                width: 100%;
                height: 2.5rem;
                margin-top: 4px;
            }
        }
    }

    .botonera-estados {
        .col.estado {
            position: relative;

            .badge {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                color: black;
                font-size: 70%;
                z-index: 10;
                border-radius: 25%;
                padding: 0.25em 0.5em;
                background-color: white;
                box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
                font-weight: bold;

                &.left {
                    right: unset;
                    left: 0;
                }
            }

            .cantidades {
                min-height: 1em;
                font-size: 130%;

                i {
                    margin-right: 0.5em;
                }
            }
        }

        .card {
            position: relative;
            color: white;
            cursor: pointer;
            margin: 0;
            padding: 0.5em;
            transition: all 0.2s ease-in-out;
            min-height: 4em;
            align-items: center;
            justify-content: center;
            display: flex;
            min-width: 12rem;
            flex-grow: 1;

            .background {
                transition: all 0.3s ease-in-out;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            span {
                z-index: 2;
                position: relative;
                font-size: 110%;
                font-weight: bold;
                font-variant: small-caps;
            }

            &.todos {
                color: black;
            }

            &.no-seleccionado {
                color: black;

                .background {
                    height: 5px;
                }

                .cantidades {
                    display: none;
                }
            }

            &:hover {
                transform: translateY(2px);

                .background {
                    filter: brightness(75%);
                }
            }
        }
    }

    .gestor-form {
        .p-button.p-togglebutton {
            .p-button-text.p-unselectable-text {
                line-height: 2em;
                height: 100%;
                display: block;
                padding: 0.5em;
            }
        }
    }

    .p-dialog.gestor-dialog {
        max-width: 65vw;
        max-height: 90vh;
        .p-dialog-header-icon:last-child {
            position: absolute;
            top: 3px;
            right: 0;
            z-index: 2;
        }

        &.large {
            max-width: 90%;
            min-width: 75vw;
            min-height: 60vh;
        }
        &.mid {
            max-width: 70%;
            min-width: 50vw;
            min-height: 60vh;
        }

        &.white {
            background-color: #ffffff !important;
        }
    }

    .p-dialog.no-header {
        .p-dialog-titlebar {
            height: 0;
            z-index: 1000;
        }
    }

    .mat-datepicker-toggle-default-icon {
        color: @main-dark;
    }

    .p-inputnumber-buttons-horizontal .p-inputnumber-button-up,
    .p-inputnumber-buttons-horizontal .p-inputnumber-button-down {
        border-radius: 0;
        width: 2em;
        // height: 2em;
    }

    .p-overlaypanel-content {
        .options {
            padding: 0;

            li {
                color: @main-dark;
                transition: all 0.3s ease-in-out;
                padding: 0.25em 1em;
                cursor: pointer;
                display: block;

                &:hover {
                    color: @main-selected-text;
                    background-color: @main-selected;
                }
            }
        }
    }

    .mat-form-field-wrapper,
    .p-panel {
        .p-dataview {
            .p-dataview-header,
            .p-dataview-footer,
            .p-dataview-content {
                border: none;

                & > .p-g > .grid:nth-child(even) {
                    background-color: @background-light;
                }

                .mat-form-field-wrapper {
                    margin-bottom: -1.25em;
                }
            }
        }

        border: none;

        p-header {
            input {
                border: none;
                padding: 0.5em;
                border-bottom: 1px solid @main-brand;
                background-color: transparent;
            }
        }

        .p-panel-footer {
            text-align: right;
        }

        .p-panel-titlebar,
        .p-panel-footer {
            background-color: #fafafa;
            color: @main-dark;
            border: none;

            .accion {
                border-radius: 0;
                background-color: transparent;
                box-shadow: none;

                &:first-child {
                    margin-left: 0.25em;
                }

                span {
                    color: @main-dark;
                }

                &:hover {
                    span {
                        color: @main-selected-text;
                    }
                }
            }

            .p-panel-titlebar-icon {
                span {
                    color: @main-dark;
                }

                &:hover {
                    span {
                        color: @main-brand-light;
                    }
                }
            }
        }
    }

    .p-dataview {
        .p-dataview-content {
            padding-top: 0;
        }
    }

    .nopad {
        padding: 0;
    }

    .col-fixed.col-codigo {
        width: 6em;
    }

    .p-picklist-listwrapper {
        width: 30vw;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .padding-material {
        padding-top: 1.25em;
    }

    .p-tabview {
        border: none;

        .p-tabview-nav {
            border: none;
            border-bottom: none !important;

            li {
                font-size: 110%;
            }

            li:focus,
            li:active {
                outline: none !important;
            }
        }
    }

    span.small {
        font-size: 75%;
    }

    .filtros {
        .botonera {
            display: flex;
            flex-wrap: wrap;

            button.p-button {
                transition: all 0.2s ease-in-out;

                &.sin-comprobante {
                    color: white;
                    background-color: @success;
                    border-color: @success;
                }

                &:hover {
                    color: white;
                    font-weight: bolder;
                    opacity: 0.7;
                }

                color: white;
                padding: 0.25em 0.5em;
                flex-grow: 1;
                min-width: 10em;
                width: 100%;
                box-shadow: none;
                transition: 0.2s all ease-in-out;

                height: 3em;

                &.no-seleccionado {
                    border-bottom: 4px solid;
                    color: unset;
                    background-color: white !important;

                    &:hover {
                        color: @main-selected;
                        font-weight: bolder;
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    span.data {
        i:first-child {
            margin-right: 1em;
        }
    }

    mat-label {
        background-color: white;
        padding: 3px;
        &.error {
            color: red;
        }
    }

    span.data {
        i:first-child {
            margin-right: 1em;
        }
    }

    .estado {
        &.A,
        &.PP {
            color: @warning;
            border-color: @warning;
        }

        &.F {
            color: @success;
            border-color: @success;
        }

        &.P,
        &.N {
            color: @info;
            border-color: @info;
        }

        &.PV {
            color: @warning;
            border-color: @warning;
        }

        &.E {
            color: @danger;
            border-color: @danger;
        }

        &.R,
        &.V,
        &.AN {
            color: @error;
            border-color: @error;
        }
    }

    span.small {
        font-size: 75%;
    }

    div.overflow {
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    mat-form-field.readonly,
    mat-form-field.mat-form-field-disabled {
        mat-label {
            background-color: transparent;
            padding: 3px;
        }

        .mat-placeholder-required {
            display: none;
        }

        .mat-form-field-wrapper .mat-form-field-flex {
            .mat-form-field-outline {
                border-radius: 6px;
                background-color: @readonly-background;

                .mat-form-field-outline-start,
                .mat-form-field-outline-end,
                .mat-form-field-outline-gap {
                    border: none;
                }
            }
        }
    }

    .model-list .top-section,
    .model-list .bottom-section,
    .model-gestor .header-section {
        .p-table tr:focus {
            outline: none;
        }
    }

    .p-contextmenu {
        z-index: 10000000 !important;
        position: absolute;
    }

    .mat-tab-labels {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .warning {
        color: @warning;
    }

    input[type='number'] {
        text-align: right;
    }

    .timepicker-overlay {
        z-index: 10000 !important;
    }

    .timepicker-backdrop-overlay {
        z-index: 10000 !important;
    }

    .p-carousel {
        .p-carousel-content {
            .p-carousel-container,
            .p-carousel-dots-container {
                &.hidden {
                    display: none !important;
                }

                .p-button {
                    background-color: transparent;
                    box-shadow: none;
                    border-radius: 0;

                    span {
                        color: @main-brand;
                    }
                }
            }
        }

        &.imagenes {
            .p-carousel-container {
                .p-carousel-item {
                    padding: 0 5px;
                }

                .p-button.p-carousel-next,
                .p-button.p-carousel-prev {
                    padding: 0;
                    height: 6em;
                    position: relative;
                }
            }
        }

        .p-carousel-content .p-carousel-item .imagen-carousel-detalle > .grid {
            border: 1px solid @primary-color;
            border-radius: 3px;
            margin: 0.3em;
            text-align: center;
            padding: 2em 0 2.25em 0;
            position: relative;
            transition: all 0.1s ease-in;
            box-sizing: border-box;

            &:hover {
                border: 2px solid @primary-color;
            }

            &.es-perfil {
                border: 3px solid @primary-color;
            }

            button.delete {
                position: absolute;
                border-radius: 0;
                border-bottom-left-radius: 6px;
                right: 0;
                top: 0;
                width: 25px;
                height: 25px;
                font-size: 80%;
                transition: all 0.1s ease-in;

                &:hover {
                    width: 36px;
                    height: 36px;
                    font-size: 100%;
                }
            }
        }

        .p-carousel-content .p-carousel-item .imagen-carousel-detalle {
            .imagen {
                overflow: hidden;
                cursor: pointer;

                img {
                    object-fit: contain;
                    height: 10vh;
                    width: auto;
                }
            }
        }

        .p-carousel-dot-icon {
            width: 16px !important;
            height: 16px !important;
            border-radius: 50%;
        }

        &.p-carousel-horizontal .p-carousel-content .p-carousel-item.p-carousel-item-start .imagen-carousel-details > .grid {
            margin-left: 0.6em;
        }

        &.p-carousel-horizontal .p-carousel-content .p-carousel-item.p-carousel-item-end .imagen-carousel-details > .grid {
            margin-right: 0.6em;
        }
    }

    i.inline-loading {
        position: absolute;
        right: 0;
        top: 1.5em;
        font-size: 200%;
    }

    i.dummy {
        padding: 0.5em 0.75em 0.5em 0;
    }

    .mat-icon.fa {
        font-size: 20px;
    }

    i,
    span {
        &.warning {
            color: @warning;
        }

        &.error {
            color: @error;
        }
    }

    .grid {
        .checkbox-container {
            padding-top: 1.25em;
        }
    }

    .grid .col-center {
        display: flex;
        align-items: center;

        &.text-right {
            justify-content: flex-end;
        }
    }

    .cdk-overlay-container {
        z-index: 9999 !important;
    }

    .option-image {
        height: 3em;
        display: flex;
        align-items: center;

        img {
            width: auto;
            -o-object-fit: cover;
            object-fit: contain;
            padding: 0;
            box-sizing: border-box;
            height: 1.5em;
        }

        span {
            display: block;
            padding: 0 0.5em;
        }
    }

    .p-messages {
        position: fixed;
        z-index: 8000;
        right: 2em;
        top: 0;
        min-width: 36em;
        flex-wrap: wrap;
        font-size: 12pt;
    }

    .p-widget-overlay {
        opacity: unset;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .p-table-loading.p-widget-overlay {
        background-color: rgba(255, 255, 255, 0.6);
    }

    span.link {
        color: blue;
        cursor: pointer;
    }

    .nowrap {
        display: flex;
        flex-wrap: nowrap;
    }

    span,
    a {
        &.overflow {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;

            &.oneline {
                white-space: nowrap;
            }
        }
    }

    span,
    i,
    p,
    strong,
    label,
    mat-icon,
    h2 {
        &.succes {
            color: @success;
        }

        &.error {
            color: @error;
        }

        &.clickable {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                font-weight: bold;
                color: @main-selected;
            }
            &:hover:not(mat-icon):not(i) {
                text-decoration: underline;
            }
        }

        &.chip {
            text-align: center;
            color: #fff;
            display: block;
            width: 90%;
            height: 1.75rem;
            border-radius: 3px;
            padding: calc(0.75rem / 2) 3px;
            margin: 0 auto;

            background-color: @text;

            &.clickable:hover {
                color: @main-selected-text;
                background-color: @main-selected;
            }
        }
    }

    button.p-button {
        border: none;
        border-radius: 2px;
        color: @primary-color-text;

        &:enabled:not(:focus):hover {
            background-color: @secondary-color;
            color: @secondary-color-text;

            &.accion-trash {
                color: @danger;
                background-color: #fff;
                border: none;
                &:hover {
                    border: none;
                }
            }

            &.accion-adjunto {
                background-color: transparent;
                color: @text;
                &:hover {
                    background-color: transparent;
                    color: @text;
                }
            }
        }

        &.accion-adjunto {
            background-color: transparent;
            color: @text;
            &:hover {
                background-color: transparent;
                color: @text;
            }
        }

        &.accion-trash {
            color: @danger;
            background-color: #fff;
            border: none;
        }
    }

    .text-center {
        text-align: center;
    }

    .footer-buttons {
        display: flex;
        flex-wrap: nowrap;
        padding: 2em;
        max-width: 100%;

        button.p-button:first-child {
            background-color: @primary-color;
        }
    }

    .p-button.p-link:enabled:not(:focus):hover {
        background-color: transparent;
        font-weight: bold;
        text-decoration: underline !important;
        color: @main-selected;
    }

    button.p-button.p-link {
        border: none;
        background-color: transparent;
        color: @main-dark;
        box-shadow: none;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            font-weight: bold;
            text-decoration: underline !important;
            color: @main-selected;
        }
    }

    .has-footer {
        margin-bottom: 4em;
    }

    .acciones {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        align-content: flex-end;

        button {
            margin-left: 0.25em;
        }
    }

    .header {
        padding: 1em 0.25em;
    }

    span.nombre.actor {
        color: @terceary-color;
        font-weight: bold;
    }

    .card {
        span.fecha-fin {
            margin-left: auto;
            font-size: 9pt;
            color: @secondary-color;
        }
    }

    li.item-lista,
    div.item-lista {
        padding: 0;
        margin: 0.25em 2em;
        border-bottom: 1px solid @primary-color;
        display: flex;
        flex-wrap: nowrap;

        button.p-button {
            margin-left: auto;
            align-self: center;
        }
    }

    span.resultado {
        color: @secondary-color;
        font-weight: bolder;
        font-variant: small-caps;
    }

    span.estado {
        font-variant: small-caps;
        font-weight: bolder;
        color: @secondary-color;
    }

    .layout-wrapper .layout-menu .profile .profile-image {
        width: auto;
        height: 80px;
    }
    .layout-wrapper .layout-main .layout-content {
        padding: 0.5em 5px;
        overflow-x: hidden;
        position: relative;
    }
    .layout-wrapper .layout-menu .profile {
        padding-top: 1em;
        background: none;
        transition: 0.3s all ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }

    h3,
    h2,
    h1 {
        margin: 0 0;
    }

    background-color: white;

    .text-right {
        text-align: right;
    }

    .d-block {
        display: block;
        width: 100%;
    }

    .actions {
        text-align: center;
        button.p-button {
            margin-left: 2px;
            border-radius: 4px;
            height: 100%;
        }
        &.text-right {
            text-align: right;
        }
        layout-picker {
            display: inline-block;
            height: 100%;
        }
        selector-idioma {
            display: inline-block;
        }
    }

    button.p-button.contorno {
        background-color: transparent;
        border: 1px solid @secondary-color;
        color: @secondary-color;
        box-shadow: none;
        margin: 0 0.1em;

        &.p-button-danger {
            border: 1px solid @error;
            color: @error;
        }

        &.p-button-success {
            border: 1px solid @success;
            color: @success;
        }
    }

    button.p-button.contorno:focus,
    button.p-button.contorno:active {
        background-color: @terceary-color;
        color: white;
        border: 1px solid @terceary-color;
    }

    button.p-button.contorno:hover,
    .p-button.contorno:enabled:not(:focus):hover {
        background-color: @secondary-color;
        color: white;
    }

    button.p-button.p-button-danger.contorno:hover,
    .p-button.p-button-danger.contorno:enabled:not(:focus):hover {
        background-color: @error;
        color: white;
    }

    button.p-button.p-button-success.contorno:hover,
    .p-button.p-button-success.contorno:enabled:not(:focus):hover {
        background-color: @success;
        color: white;
    }

    .p-widget-header.p-table-caption {
        background-color: transparent;
        color: @main-dark;
        border: 1px solid lightgray;
        border: 1px solid lightgray;
        background-color: white;
        margin-top: 6px;
        border-radius: 7px;

        input {
            color: @secondary-color;
        }

        input.p-inputtext::placeholder {
            color: gray;
            opacity: 0.75;
        }

        button {
            background-color: transparent;
            border: 1px solid @secondary-color;
            color: @secondary-color;
            box-shadow: none;
            width: auto;

            button.p-button:focus,
            button.p-button:active {
                background-color: @secondary-color;
                color: white;
            }
        }
    }

    .header {
        .title {
            margin: 0;
            padding: 0.25em 0.1em;
        }
    }

    .header-autocomplete {
        padding: 5px;
        &:hover {
            cursor: pointer;
        }
    }
}

.layout-main {
    color: @main-dark;

    input {
        color: @main-dark;
    }

    ul,
    .items {
        list-style-type: none;
        color: @primary-color-text;

        .opcion {
            border-bottom: 1px solid @primary-color-text;
            padding: 5px;
            cursor: pointer;
            display: flex;
            flex-wrap: nowrap;

            &:hover {
                color: @primary-color-text;
                font-weight: bold;
                background-color: @main-selected;
            }

            button {
                border: none;
                background: transparent;
                color: red;
                float: right;
            }

            .acciones {
                margin-left: auto;
            }

            .banned {
                padding-right: 6px;
                float: right;
                font-size: 20px;
            }
        }
    }

    .p-button-rounded button {
        background: white;
        box-shadow: none;
        border: 1px solid @secondary-color;

        span {
            color: @secondary-color !important;
        }

        &:hover,
        &:focus {
            background-color: @secondary-color !important;

            span {
                color: white !important;
            }
        }
    }

    textarea {
        font-family: inherit;
        border-top: none;
        border-left: none;
        border-right: none;
        color: inherit;
        font-size: inherit;
    }
}

.float-right-button {
    width: 100%;

    button {
        float: right;
    }
}

.bold {
    font-weight: bold;
}

.subtitulo {
    font-size: 10pt;
}

.c-primary {
    color: @primary-color;
}

.c-secondary {
    color: @secondary-color;
}

.c-terceary {
    color: @terceary-color;
}

.halfButton {
    width: 50%;

    button {
        width: 100% !important;
        border-radius: 0 !important;
    }

    &.first {
        button {
            background: gray;
        }
    }
}

.halfButton {
    width: 50%;

    button {
        width: 100% !important;
    }
}

.secondaryButton button {
    background-color: gray !important;
}

.p-confirmdialog {
    width: fit-content !important;

    i {
        display: none !important;
    }

    button {
        width: 50% !important;
        margin-right: 0 !important;
        padding-left: 0 !important;

        &:nth-child(2) {
            background-color: gray !important;
        }

        &:only-child {
            width: 100% !important;
        }

        .p-button-icon-left {
            display: none;
        }
    }
}

.respuestaText {
    border: 1px solid gray !important;
}

.menu-button {
    color: white;
    font-size: 20px;
}

.fRight {
    float: right;
}

.checkboxSeparator div {
    border-bottom: 1px solid darkgray;
    padding: 1px;
}

.filtroBusqueda {
    border: 1px solid @primary-color !important;
    width: 100%;
    padding: 10px !important;
    margin: 10px 0;

    & + i {
        float: right;
        margin-top: -42px;
        margin-right: 10px;
        color: @primary-color !important;
    }
}

.login {
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: white;

    .login-content {
        display: flex;

        height: 50%;
        width: 100%;
        align-self: center;

        .left,
        .right {
            padding: 1.5em;
            display: flex;
            flex-wrap: wrap;
        }

        .left {
            img {
                align-self: center;
                width: 50%;
                height: auto;
                margin: 0 auto;
            }
        }

        .right {
            border-left: 1px solid @main-dark;
            height: 100%;
            padding-left: 3em;

            .footer-buttons {
                text-align: center;
                padding: 0;

                button {
                    min-width: 10em;
                    margin: 0 auto;
                }
            }

            form {
                align-self: center;

                input {
                    margin: 0.5em auto;
                    border: none;
                    background-color: @main-border-light;
                    border-radius: 4px;
                    //font-size: 12pt;
                    padding: 1em;
                }

                label {
                    color: @main-dark;

                    line-height: 4;
                }

                .social {
                    button.p-button-icon-only {
                        border-radius: 0 !important;
                        width: 100%;
                    }

                    .gmail-button {
                        background-color: #d44638;
                    }

                    .facebook-button {
                        background-color: #4267b2;
                    }
                }

                a.olvide {
                    padding: 0.5em;
                    width: 100%;
                    text-align: center;
                }

                .login-button {
                    padding: 0.75em 2.5em;
                    width: 75%;
                    display: block;
                    background-color: @main-dark;
                    color: white;
                    font-weight: bold;
                    font-size: 12pt;
                }
            }
        }
    }
}

/*
DRAGULAR
*/
/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    filter: alpha(opacity=80);
    pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
    filter: alpha(opacity=20);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: @accent1;
}
.p-inputgroup .p-inputtext,
.p-fluid .p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper,
.p-inputgroup .p-inputwrapper > .p-component {
    width: 100%;
}
@media (max-width: 640px) {
    body {
        // font-size: 100%;

        .p-dialog {
            max-width: 100vw;
        }

        .layout-wrapper .layout-main .layout-content .model-list .top-section .model-title {
            padding: 1.5em 1em 0.25em 1em;
        }

        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding: 1em 0 1.5em 0;
        }

        .model-gestor {
            .model-dataContent {
                .pic-wrapper {
                    padding: 12px;
                }
            }

            .gestor-form {
                padding: 0;

                .p-button.p-togglebutton {
                    max-width: 75vw;
                }
            }
        }

        .p-message {
            position: fixed;
            width: 20em;
            top: unset !important;
            bottom: 2em;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .has-footer {
            margin-bottom: 4em;
        }

        .footer-buttons {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-wrap: nowrap;
            padding: 0;
            margin-top: 0;
            max-width: 100%;
        }
    }
}

.opcion {
    display: flex;
    width: 100%;
    padding: 0.2em 0.5em;

    .label-container {
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;

        label {
            white-space: nowrap;
        }
    }

    button {
        margin-left: auto;
    }
}

.letra-chica {
    font-size: 12px;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

i.menu-icon {
    width: 2em;
}

.smallcaps {
    font-variant: small-caps;
}

.p-menu-dynamic {
    min-width: 240px !important;
    width: 23vw;

    .p-menuitem-icon {
        width: 1.5em !important;
    }
}

.fa {
    &.A:before {
        content: '\F00C';
        color: @success;
    }

    &.C:before {
        content: '\f05e';
        color: @error-dark;
    }

    &.R:before {
        content: '\F00D';
        color: @error-dark;
    }

    &.P:before {
        content: '\F128';
        color: @warning;
    }

    &.PP:before {
        content: '\f0d6';
        color: @main-brand;
    }

    &.L:before {
        content: '\f11e';
        color: @success;
    }
}

.subtitle {
    color: black;
    margin: 0;
    padding: 0.5em 0em;
    margin-right: 1em;
}

td {
    .mat-form-field-wrapper {
        padding: 0;
    }

    .mat-form-field {
        width: 100%;
    }

    &.group-input {
        .mat-form-field {
            width: 90%;
        }
    }
}

.no-pad {
    padding: 0 !important;
}

.color-warning {
    color: @warning;
}

.color-disabled {
    color: @disabled;
}

.loading {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: @primary-color;
    color: @primary-color-text;
    width: 20%;
    display: flex;
    border-radius: 3px;
    padding: 0.25em 0.5em;
    border-top-left-radius: 20px;

    span {
        display: block;
        margin-left: auto;
    }
}

//diego
body {
    .p-dialog-content {
        overflow: visible;
        .model-gestor {
            .botonera {
                right: unset;
            }
        }
    }

    .fa-circle {
        font-size: 1rem;
    }

    .medium {
        font-size: 1.1rem;
    }

    .user-link {
        color: @user-link-color;
    }

    .color-danger {
        color: @danger;
    }

    .color-success {
        color: @success;
    }

    .color-warning {
        color: @warning;
    }

    h2 {
        font-family: @h-font;
        font-size: @h2-section-font-size;
        font-weight: @h2-section-font-weight;
        color: @accent1;

        &.font-size-gestor {
            font-size: @h2-gestor-font-size;
        }
    }

    h3 {
        font-family: @h-font;
        font-size: @h3-section-font-size;
        font-weight: @h3-section-font-weight;
        color: @text;
    }

    .p-sortable-column-icon::before {
        color: @sort-icon-color;
    }

    .layout-topbar {
        .layout-topbar-right {
            background-color: @top-bar-right-background;
        }
    }
    //******************************************
    .p-float-label {
        .autocomplete-multiple-chips {
            width: 100%;
            border: none;
            outline: none;
        }

        .p-inputtext,
        .p-inputnumber {
            min-height: 3rem;

            &:not(.ng-valid)[required] {
                ~ label {
                    &::after {
                        content: ' *';
                        color: @danger;
                    }
                }
            }
        }

        & > .p-inputtext {
            height: 3.15rem;
        }

        label {
            // padding: 2px;
            font-size: 0.95rem;
            font-family: @h-font;
            color: @accent2-text;
            font-weight: 700;
            z-index: 1;
            background-color: white;
            transition-duration: 0.2s;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            width: fit-content;
            max-width: 90%;
            text-overflow: ellipsis;
            border-radius: 10px;
        }

        & > .ng-invalid.ng-touched + label {
            color: @danger2;
        }

        i.pi,
        i.fa {
            z-index: 10;
        }

        .p-autocomplete-dropdown {
            width: 1.8rem;
            background: white;
            border: none;
            // border: 1px solid rgba(0, 0, 0, 0.38);
            // border-left: none;

            .p-button-icon {
                &.pi-chevron-down {
                    color: rgba(0, 0, 0, 0.38);
                }
            }
        }

        .p-inputtext.ng-dirty.ng-invalid,
        .p-inputtext.ng-touched.ng-invalid {
            border-color: @danger;
        }
    }

    .wrapper-switch {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .label-switch {
            font-size: 0.95rem;
            font-family: @h-font;
            color: @accent2-text;
            font-weight: 700;
            z-index: 1;
            background-color: white;
            border-radius: 10px;
            margin-bottom: 5px;
        }

        .p-inputswitch {
            &.p-inputswitch-checked {
                .p-inputswitch-slider:before {
                    background-color: @accent1;
                }
            }
        }
    }

    //**************************

    .p-inputgroup {
        margin-top: 4px;
        .p-float-label {
            &:first-child {
                input {
                    border-radius: 4px;
                }
            }
        }

        position: relative;
        // para label error bajo el input
        // min-height: 4.3rem;

        &.descriptivo-material-selector {
            input {
                &.p-autocomplete-input {
                    &.p-inputtext {
                        height: 3rem;
                        border: none;
                        border-radius: 10px;
                        box-shadow: none;

                        &:enabled:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            &.con-addon {
                input {
                    border: none;
                    // border-radius: 0;
                    // border-left: none;
                }

                .p-inputgroup-addon {
                    background-color: #f5f5f5;
                    min-width: 2rem;
                    border: none;

                    &:hover {
                        background-color: #6c6c6c;
                        i.fa-eraser::before {
                            color: #f5f5f5;
                        }
                    }
                }
            }

            &:focus-within {
                outline: 1px solid #6c6c6c;
            }

            &.readonly {
                &:focus-within {
                    outline: none;
                }
                &:hover {
                    border: none;
                }
            }
        }
        &.selector-multiple-chips {
            .autocomplete-multiple-chips {
                width: 100%;

                ul {
                    background-image: none;
                    background-color: transparent;
                    border: none;

                    padding: 0;
                    padding-top: 5px;

                    &.p-focus {
                        box-shadow: none;
                    }

                    li {
                        &.p-autocomplete-token {
                            height: 2rem;
                            border-radius: 14px;
                            margin: 5px 4px 5px 4px;
                        }
                    }
                }
                .panel-autocomplete {
                    padding-left: 1rem;
                    font-size: 1rem;
                }
            }
            .input-autocomplete {
                width: 100%;
                padding-left: 1rem;
                padding-top: 0.5rem;
                padding-bottom: 0px;
                background-color: transparent;
                background-image: none;
                border: none;
            }
        }

        &.readonly {
            border: none;
            background-color: @readonly-background;
            min-height: 0;
            border-radius: 4px;

            .p-autocomplete-dropdown {
                visibility: hidden;
                width: 0;
            }

            &.p-float-label,
            .p-float-label {
                .p-inputtext,
                .p-inputnumber {
                    border-radius: 4px;
                    border: none;
                    background-color: #efefef;
                    min-height: 3rem;
                    &.p-focus {
                        box-shadow: none;
                    }
                }

                label {
                    top: 0.1rem;
                    background-color: transparent;
                    font-size: 0.8rem;
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-block;
                    width: 90%;
                    text-overflow: ellipsis;
                    color: #000;
                }

                .p-inputtext:focus,
                .p-inputnumber:focus {
                    box-shadow: none;
                }

                &:focus-within,
                &:hover {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
            }

            .p-inputgroup-addon {
                display: none;
                width: 0;
            }
        }

        .p-error {
            position: absolute;
            bottom: 2px;
            left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            // para label error bajo el input quitar siguiente linea (font-size: 10px)
            font-size: 10px;
            z-index: 10;
        }

        .p-calendar.p-calendar-w-btn.p-calendar-disabled {
            background-color: #efefef;
            border: none;
        }
    }

    .model-title {
        .p-inputgroup {
            min-height: 0;
        }
    }

    .button-wrapper .p-inputgroup {
        padding: 10px;
        height: 48px;

        & > label {
            font-size: 11px;
            margin-top: -16px;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 2px;
            z-index: 1;
        }

        & > div {
            position: absolute;
            margin-top: -8px;
            background-color: #fff;
            padding: 13px;
            border-radius: 0px;
            border: 1px solid rgb(145, 145, 145);
        }

        i {
            z-index: 2;
        }

        &.readonly {
            & > div {
                background-color: #efefef;
                margin-top: -8px;
                padding: 14px;
                border-radius: 6px;
                border: none;
            }
        }
    }

    .p-togglebutton {
        &.p-button {
            height: 2.65rem;
            margin-top: 0.25em;
            align-items: center;

            &.p-highlight {
                color: @text-in-button-ligth;
                background-color: @accent1;
            }

            &:focus {
                background-color: #fff;

                &.p-highlight {
                    color: @text-in-button-ligth;
                    background-color: @accent1;
                }
            }
        }
    }

    .button-wrapper {
        align-items: flex-start;
    }

    .disponibilidad-contenedor {
        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding-bottom: 0px;
            padding-top: 10px;
        }

        .p-dataview-content {
            padding-top: 0px;
        }

        .grid {
            margin: 0px;

            .col {
                padding: 0px;
            }
        }

        .mat-form-field {
            .mat-form-field-wrapper {
                padding-bottom: 0px;
            }

            &.readonly {
                .mat-form-field-wrapper {
                    margin-bottom: 0px;

                    .mat-form-field-flex {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }
        }

        #agregarBtn {
            background-color: @accent1;

            &:hover {
                background-color: @accent2;
            }
        }
    }
}

// fin diego

// Diego

body {
    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }

    .p-datatable {
        &.p-datatable-hoverable-rows {
            .p-datatable-tbody > tr:not(.p-highlight):hover {
                background: #ffffffa6;
                color: rgba(0, 0, 0, 0.87);
            }
        }

        thead {
            th {
                &.money-tabla {
                    text-align: right;
                }
            }
        }

        table {
            tr {
                td {
                    &.money-tabla {
                        padding-right: 0.75rem;
                    }
                    .p-button-icon {
                        &.pi {
                            &.pi-cog {
                                color: @accent1;
                            }
                        }
                    }
                }
            }
        }
    }

    .gestor-menu-table {
        font-family: @common-font;
        font-size: 12px;

        tr {
            td {
                &:first-child {
                    text-align: center;
                }
            }
        }

        .p-datatable-tbody > tr > td {
            padding: 5px;
        }

        .p-datatable-thead th {
            padding: 5px;
        }

        mat-form-field.readonly,
        mat-form-field.mat-form-field-disabled {
            .mat-form-field-wrapper .mat-form-field-flex {
                .mat-form-field-outline {
                    background-color: transparent;
                }
            }
        }

        .mat-chip {
            font-family: @common-font;
            font-size: @td-font-size;

            &.mat-standard-chip {
                background-color: @accent2;
                color: #fff;
                min-height: 1.6rem;
                margin-bottom: 1px;
            }
        }

        .mat-chip-input {
            height: 1.6rem;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline {
            top: 0px;
        }

        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding: 0;
        }

        .mat-form-field-infix {
            border: none;
        }

        .mat-form-field-flex {
            background-color: transparent;
        }

        .mat-form-field-outline {
            .mat-form-field-outline-start {
                border-color: #fff;
                &:hover {
                    border-color: #fff;
                }
            }

            .mat-form-field-outline-end {
                border-color: #fff;
            }
        }

        .p-togglebutton {
            width: 1.6rem;
            height: 1.6rem;
            margin: 5px;
            border: none;
            background-color: @danger;

            span::before {
                color: #fff;
            }

            &.p-button:focus.p-highlight {
                background-color: @accent2;
            }

            &.p-highlight {
                background-color: @accent2;

                span::before {
                    color: #fff;
                }
            }

            &.p-button:not(.p-disabled):not(.p-highlight):hover {
                background: @danger;
            }
            &.p-button:not(.p-disabled):not(.p-highlight):not(:hover) {
                background: @danger;
            }
        }

        .p-button {
            &.p-button-icon-only {
                width: 27px;
                padding: 8px;
            }
        }
    }

    button {
        &.p-button {
            &.agregar-button {
                color: @text-in-button-ligth;
                border: 1px solid @accent1;
                background-color: @accent1;

                &:enabled:hover {
                    background-color: @accent2;
                }
            }

            &.p-button-icon-only {
                &.edit-button {
                    margin-left: 1rem;
                    color: @text-in-button-ligth;
                    border: 1px solid @accent1;
                    background-color: @accent1;

                    &:hover {
                        border: 1px solid @accent2;
                        background-color: @accent2;
                    }
                }

                &.agregar-button {
                    color: @text;
                    border: 1px solid @accent1;
                    background-color: #fff;

                    &:enabled:hover {
                        background-color: @accent2;
                    }
                }

                &.p-button-success {
                    background-color: @accent1;
                    border: 1px solid @accent1;

                    &:focus {
                        background-color: @accent1;
                    }
                }
            }

            &.p-button-outlined {
                &:not(.accion-tabla) {
                    // color: @text;
                    // border: 1px solid @accent2;
                }
            }

            &.p-button-text {
                &.accion-quitar {
                    color: @danger;
                    background-color: #fff;

                    &:hover {
                        color: #fff;
                        background-color: @danger;
                    }
                }
            }
        }
    }

    .p-button-icon {
        &.pi {
            &.pi-cog {
                color: @accent1;
            }
        }
    }

    div {
        .p-paginator-bottom {
            &.p-paginator {
                justify-content: flex-end;

                .p-paginator-page {
                    font-size: @td-font-size;
                    font-family: @common-font;
                    color: @terceary-color;
                    min-width: 1em;

                    &.p-highlight {
                        background-color: #ffffff;
                    }

                    &:hover {
                        background-color: #ffffff;
                    }
                }
                .p-paginator-first,
                .p-paginator-prev,
                .p-paginator-next,
                .p-paginator-last {
                    min-width: 1rem;
                    color: @terceary-color;
                }

                .p-paginator-rpp-options {
                    background-color: #ffffff;
                    font-size: @td-font-size;
                    font-family: @common-font;
                    color: @terceary-color;

                    &.p-dropdown {
                        border: 1px solid @accent1;
                        border-radius: 0px;
                        height: 2em;
                        width: 4em;

                        .p-dropdown-label {
                            padding-top: 7%;
                            padding-left: 18%;
                            font-size: @td-font-size;
                            font-family: @common-font;
                            color: @accent1;
                        }

                        .pi.pi-chevron-down {
                            font-size: 0.8em;
                            font-family: 'primeicons';
                            color: @accent1;
                        }

                        .p-dropdown-trigger {
                            width: 2em;
                        }
                    }
                }
            }
        }
    }

    .p-listbox {
        margin-bottom: 0.5rem;

        .p-listbox-list {
            padding-top: 1rem;
            padding-bottom: 1rem;

            .p-listbox-item {
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                font-family: @common-font;
            }
        }
    }

    .overlay-idioma {
        &.p-overlaypanel {
            .p-overlaypanel-content {
                padding: 0.6rem;

                .opcion-idioma {
                    display: flex;
                    align-items: center;
                    margin-top: 0.4rem;

                    span {
                        font-size: 0.7rem;
                        margin-left: 0.2rem;
                    }
                }
            }
        }
    }
}

.gestor-pedido {
    .p-dataview-header {
        padding: 0;
    }
    .p-tabview {
        li.p-tabview-ink-bar {
            height: 0px;
        }

        .tab-categorias {
            border-top: 1px solid @accent1;

            ul {
                margin-top: 2px;
            }
        }

        .p-tabview-nav {
            li {
                &.p-highlight {
                    .p-tabview-nav-link {
                        background: @accent1;
                        color: #fff;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}

.p-chip {
    img {
        height: 1.6rem;
        width: 1.6rem;
    }

    .p-chip-text {
        font-family: @common-font;
        font-size: 0.85rem;
    }
}

.p-chip-image {
    height: 1.7rem;
}

.p-tabview .p-tabview-nav {
    .p-tabview-ink-bar {
        background-color: @accent2;
    }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: @accent1;
    border-color: transparent;
    color: @accent1-text;
}

.ripple-primary {
    .p-ink {
        background-color: @primary-color;
        opacity: 0.3;
    }
}
.ripple-secondary {
    .p-ink {
        background-color: @secondary-color;
        opacity: 0.3;
    }
}
.ripple-terceary {
    .p-ink {
        background-color: @terceary-color;
        opacity: 0.3;
    }
}
.ripple-accent {
    .p-ink {
        background-color: @accent1;
        opacity: 0.3;
    }
}
.font-awesome-15px {
    font-size: 15px;
}

@media (min-width: 992px) {
    .layout-menu-static.layout-menu-active .layout-main {
        margin-left: 0rem;
    }
}

@media (min-width: 1025px) {
    .layout-menu-static.layout-menu-active .layout-main {
        margin-left: 17rem;
    }
}

@media (max-width: 1024px) {
    .listado-pedido tr th button {
        display: none;
    }
}

@import 'assets/implementacion/styles/theme-implementacion.less';
@import 'assets/styles/impresion.less';

*:focus-visible {
    outline: none !important;
}

//Error de prime, se cuelga la app al cerrar un sidebar -> quitar cuando se solucione
.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0, 0, 0, 0.32);
    }
}
@keyframes p-component-overlay-leave-animation {
    from {
        background-color: rgba(0, 0, 0, 0.32);
    }
    to {
        background-color: transparent;
    }
}
