@media print {
    body {
        .qr-container {
            page-break-inside: avoid;
        }
        .print-only {
            visibility: visible;
        }
        .layout-menu-static.layout-menu-active .layout-main {
            margin-left: 0;
        }

        font-family: 'Roboto', Arial, Helvetica, sans-serif;

        //min-width: 1024px;
        .p-datatable table td,
        .p-datatable table th {
            display: table-cell !important;
            font-size: 9pt !important;

            &.print-hidden {
                display: none !important;
            }
        }

        .print-hidden,
        .layout-topbar,
        .layout-menu,
        .menu-wrapper,
        .p-paginator,
        .title-tabs .tab,
        filtro,
        .mat-datepicker-toggle-default-icon,
        button.p-button,
        .p-button {
            display: none !important;
        }

        .layout-wrapper .layout-main {
            padding-top: 0 !important;
            .layout-content {
                overflow-x: visible;
            }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            border: none !important;
        }

        .layout-menu-static.layout-menu-active .layout-main {
            margin-left: 4px;
            margin-right: 4px;
        }

        font: 10pt Helvetica, 'Times New Roman', Times, serif;
        line-height: 1.3;
        background: #fff !important;
        color: #000;

        h1 {
            font-size: 24pt !important;
        }
        h2 {
            font-size: 26px !important;
        }
        h3,
        h4 {
            font-size: 14pt !important;
            margin-top: 25px !important;
        }
        .col-print-12 {
            width: 100% !important;
        }
        .lg:col-1,
        .lg:col-2,
        .lg:col-3,
        .lg:col-4,
        .lg:col-5,
        .lg:col-6,
        .lg:col-7,
        .lg:col-8,
        .lg:col-9,
        .lg:col-10,
        .lg:col-11,
        .lg:col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
        }

        .lg:col-1 {
            width: 8.3333%;
        }

        .lg:col-2 {
            width: 16.6667%;
        }

        .lg:col-3 {
            width: 25%;
        }

        .lg:col-4 {
            width: 33.3333%;
        }

        .lg:col-5 {
            width: 41.6667%;
        }

        .lg:col-6 {
            width: 50%;
        }

        .lg:col-7 {
            width: 58.3333%;
        }

        .lg:col-8 {
            width: 66.6667%;
        }

        .lg:col-9 {
            width: 75%;
        }

        .lg:col-10 {
            width: 83.3333%;
        }

        .lg:col-11 {
            width: 91.6667%;
        }

        .lg:col-12 {
            width: 100%;
        }

        .lg-offset-12 {
            margin-left: 100%;
        }

        .lg-offset-11 {
            margin-left: 91.66666667%;
        }

        .lg-offset-10 {
            margin-left: 83.33333333%;
        }

        .lg-offset-9 {
            margin-left: 75%;
        }

        .lg-offset-8 {
            margin-left: 66.66666667%;
        }

        .lg-offset-7 {
            margin-left: 58.33333333%;
        }

        .lg-offset-6 {
            margin-left: 50%;
        }

        .lg-offset-5 {
            margin-left: 41.66666667%;
        }

        .lg-offset-4 {
            margin-left: 33.33333333%;
        }

        .lg-offset-3 {
            margin-left: 25%;
        }

        .lg-offset-2 {
            margin-left: 16.66666667%;
        }

        .lg-offset-1 {
            margin-left: 8.33333333%;
        }

        .lg-offset-0 {
            margin-left: 0%;
        }

        .lg-order-first {
            -ms-flex-order: -1;
            order: -1;
        }

        .lg-order-last {
            -ms-flex-order: 13;
            order: 13;
        }

        .lg-order-0 {
            -ms-flex-order: 0;
            order: 0;
        }

        .lg-order-1 {
            -ms-flex-order: 1;
            order: 1;
        }

        .lg-order-2 {
            -ms-flex-order: 2;
            order: 2;
        }

        .lg-order-3 {
            -ms-flex-order: 3;
            order: 3;
        }

        .lg-order-4 {
            -ms-flex-order: 4;
            order: 4;
        }

        .lg-order-5 {
            -ms-flex-order: 5;
            order: 5;
        }

        .lg-order-6 {
            -ms-flex-order: 6;
            order: 6;
        }

        .lg-order-7 {
            -ms-flex-order: 7;
            order: 7;
        }

        .lg-order-8 {
            -ms-flex-order: 8;
            order: 8;
        }

        .lg-order-9 {
            -ms-flex-order: 9;
            order: 9;
        }

        .lg-order-10 {
            -ms-flex-order: 10;
            order: 10;
        }

        .lg-order-11 {
            -ms-flex-order: 11;
            order: 11;
        }

        .lg-order-12 {
            -ms-flex-order: 12;
            order: 12;
        }

        .model-gestor .model-titl,
        .model-gestor .title {
            padding-bottom: 4px !important;
        }

        .mat-placeholder-required .mat-form-field-required-marker {
            display: none;
        }

        h2 {
            margin-bottom: 2px !important;
        }

        .p-editor-toolbar.ql-toolbar {
            display: none;
        }

        .p-editor-content.ql-container {
            border: none;
            min-height: unset !important;
            height: 2cm !important;
        }
        ::-webkit-input-placeholder {
            /* WebKit browsers */
            color: transparent;
        }
        :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: transparent;
        }
        ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: transparent;
        }
        :-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: transparent;
        }
        .mat-form-field-wrapper {
            margin: 0;
            padding-bottom: 0;
            margin-bottom: -1.25em !important;
            .mat-form-field-outline {
                background-color: transparent !important;
                border-bottom: 1px solid lightgray;
            }
        }
    }
}
