@import '../../../app/app.variables.less';

@keyframes solapado {
    from {
        background-color: @primary-color;
        box-shadow: 0 0 2px red;
    }

    to {
        background-color: @error;
        box-shadow: 0 0 10px red;
    }
}

body {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    background-color: #f0f0f0;

    .p-slider.p-slider-horizontal {
        height: 4px;

        .p-slider-handle {
            background-color: @main-selected;
        }

        .p-slider-range {
            background-color: @main-selected;
        }
    }

    .layout-wrapper .layout-menu.layout-menu-dark ul.ultima-menu li {
        a.active-menuitem-routerlink {
            color: white;
            // text-decoration-color: white;
            font-weight: bolder;

            i {
                text-shadow: none;
                color: white;
            }

            &:hover {
                color: @main-selected;
                font-weight: bolder;

                i {
                    color: @main-selected;
                }
            }
        }
    }

    .fa.nc,
    .fa.nd {
        &::before {
            font-size: 70%;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    .fa.nd::before {
        content: 'ND';
    }

    .fa.nc::before {
        content: 'NC';
    }

    .totales {
        box-sizing: border-box;
        margin-top: 0.5em;
        & > .grid {
            padding: 0.25em 3em;
            background-color: @primary-color;
            color: @primary-color-text;
            font-size: 100%;
            font-weight: bold;
            font-variant: petite-caps;

            span {
                display: inline-block;
            }

            .subtotal,
            .total {
                span:nth-child(2) {
                    min-width: 10vw;
                    display: inline-block;
                    text-align: right;
                }
            }

            .total {
                font-size: 130%;
            }
        }
    }
    .layout-wrapper .layout-menu .menuitem-badge {
        padding: 0.25em;
        width: 1.5em;
        height: 1.5em;
        top: 0.5em;
        color: @main-selected-text;
    }

    .layout-wrapper.menu-layout-overlay .topbar {
        z-index: 1000;
    }

    .prime-material {
        &.p-multiselect,
        &.p-calendar {
            width: 100%;
            border: none;

            input {
                background-image: none;
                padding: 0;
            }

            &:hover {
                border-color: @main-selected;
                border-width: 2px;

                .p-multiselect-trigger {
                    color: black;
                }
            }

            .p-multiselect-trigger {
                transition: all 0.2s ease-in-out;
                color: #ababab;
                height: 100%;
                width: 2em;
            }
        }
    }

    .mat-form-field-wrapper,
    .p-panel {
        .p-dataview {
            .p-dataview-header,
            .p-dataview-footer,
            .p-dataview-content {
                border: none;

                .mat-form-field-wrapper {
                    margin-bottom: -1.25em;
                }
            }
        }
    }

    .no-space-mat-form,
    mat-form-field.readonly,
    .mat-form-field-appearance-outline.readonly {
        .mat-form-field-wrapper {
            margin-bottom: -1.25em;
        }
    }

    .mat-chip.mat-standard-chip {
        background-color: @background-light;
    }

    .mat-expansion-panel-header {
        background-color: #efefef;
    }

    .mat-expansion-panel-header-title {
        flex-grow: 0.25;
        font-weight: bold;
    }

    // .mat-form-field-appearance-outline .mat-form-field-infix {
    //     padding: 0.5em 0 1em 0;
    // }

    .model-gestor {
        .model-dataContent {
            overflow: hidden;

            .pic-wrapper {
                padding: 0.25em 0.5em;
                position: relative;

                .pic {
                    width: 100%;
                    height: 100%;
                    border: 1px solid @secondary-color;
                    background-color: white;
                    border-radius: 5px;
                    transition: 0.3s all ease-in-out;
                    position: relative;

                    i.edit-icon {
                        transition: 0.3s all ease-in-out;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 20px;
                        border-top-left-radius: 5px;
                        height: 20px;
                        opacity: 0.6;
                        color: @secondary-color-text;
                        background-color: @secondary-color;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    i.delete-icon {
                        transition: 0.3s all ease-in-out;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 20px;
                        border-bottom-left-radius: 5px;
                        height: 20px;
                        opacity: 0.6;
                        color: @secondary-color-text;
                        background-color: @secondary-color;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            background-color: @danger;
                            cursor: pointer;
                            opacity: 1;
                            right: 0;
                            width: 30px;
                            height: 30px;
                        }
                    }

                    &:hover {
                        background-color: rgba(240, 240, 240, 1);

                        i.edit-icon {
                            opacity: 1;
                            right: 0;
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: @primary-color;
    }

    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: @secondary-color;
    }

    .gestor-form {
        .mat-form-field-wrapper {
            .no-hint {
                padding-bottom: 0.15em;
            }
        }

        padding: 1em 2em;
    }

    .grid [class*='col'] {
        mat-form-field {
            width: 100%;
        }

        input {
            width: 100%;
            line-height: 1;
        }
    }

    .nopad {
        padding: 0;
    }

    .p-datatable {
        th,
        td {
            text-align: left;

            &.text-right {
                text-align: right;
            }

            &.text-center {
                text-align: center;
            }
        }

        tr:hover {
            td {
                filter: brightness(0.98);
            }
        }

        td.money-column,
        td.money-tabla {
            text-align: right;
            

            span {
                text-align: right;
               
            }
        }

        th.id-column,
        td.id-column {
            width: 4.5em;
            text-align: center;
        }

        th.color-tabla,
        td.color-tabla {
            width: 5em;

            span.color {
                display: block;
                width: 3em;
                height: 1em;
            }
        }

        th,
        td {
            &.icon-tabla {
                width: 5em;
                text-align: center;
            }

            &.codigo-tabla {
                width: 7em;
                text-align: left;
            }

            input {
                // outline: none;
                // border: none;
                // border-bottom: 1px solid @primary-color;
            }
        }

        th.profile-pic,
        td.profile-pic {
            width: 5em;
            text-align: center;
        }

        th.is-boolean,
        td.is-boolean {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 5em;
            text-align: center;
        }

        .acciones-tabla {
            display: flex;
            text-align: center;
            justify-content: center;
            min-height: 2.5em;

            button.accion-tabla.p-button-icon-only {
                border-radius: 2px;
                border: none;
                background-color: transparent;
                box-shadow: none;
                color: @main-dark;
                transition: all 0.2s ease-in-out;
                font-size: 12pt;
                // width: 2em;
                padding: 12px;

                // .pi {
                //     color: @main-dark;
                // }

                &:hover {
                    opacity: 0.7;
                    background-color: @main-selected;
                    border-radius: 6px;
                    color: @main-selected-text;

                    .pi {
                        color: @main-selected-text;
                    }
                }
            }
        }
    }

    .descriptivo-material-selector {
        .mat-form-field-prefix {
            button {
                margin-right: 0.5em;
                width: 1em;
            }
        }

        // .mat-form-field-label-wrapper {
        //    top: -1.15em;
        // }

        .mat-form-field-suffix,
        .mat-form-field-prefix {
            button {
                background: none;
                color: gray;
                //top: -5px;
                border: none;
                border-radius: 0;
                box-shadow: none;

                &.p-button:enabled:not(:focus):hover {
                    background-color: transparent;
                    color: black;
                }
            }
        }
    }

    .mat-form-field.mat-focused .mat-form-field-label {
        color: @primary-color;
    }

    .mat-form-field-appearance-outline {
        .mat-form-field-flex {
            align-items: center;
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
            top: 0.15em;
        }
    }

    .mat-form-field-outline {
        background-color: white;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            border-color: @border-color;
        }

        &.mat-form-field-outline-thick {
            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-color: @primary-color;
            }
        }

        .mat-form-field-outline-start {
            border-radius: 2px 0px 0px 2px;
        }

        .mat-form-field-outline-end {
            border-radius: 0px 2px 2px 0px;
        }

        .mat-form-field-outline-gap {
            border-radius: 0;
        }

        .mat-form-field-prefix.mat-icon.fa-icon {
            top: 0;
            font-size: 0.75em;
        }
    }

    .mat-calendar-body-selected {
        background-color: @primary-color;
    }

    .layout-wrapper .topbar .topbar-right .logo .title {
        font-weight: normal;
    }

    .checkbox-accordion {
        .p-chkbox-box {
            background: white;
        }
    }
}

@media (max-width: 640px) {
    body {
        .p-dialog.gestor-dialog {
            max-width: unset;
            max-height: 100vh;
        }

        .model-gestor {
            .model-dataContent {
                .pic-wrapper {
                    padding: 12px;
                }
            }

            .gestor-form {
                padding: 0;
            }
        }

        .layout-wrapper {
            .layout-main {
                .layout-content {
                    padding: 0.5em;

                    .content.content-white {
                        background-color: white;
                        margin: -1em;
                        padding: 0 0.25em;

                        &.modal {
                            margin: 0 auto;
                        }
                    }

                    .model-gestor {
                        padding: 0 0.75em 3em 0.75em;

                        .model-title:first,
                        .title:first {
                            position: fixed;
                            z-index: 10;
                            top: 50px;
                            left: 0;
                            right: 0;
                            height: 6vh;
                            padding: 2vh 4vw;
                            background-color: white;
                            width: 100%;
                            h1,
                            h2,
                            h3,
                            h4 {
                                margin: 0;
                            }
                            span.titulo {
                                font-size: 150%;
                                font-weight: bold;
                                color: @main-dark;
                            }
                        }
                    }

                    .model-list {
                        justify-content: center;

                        .top-section,
                        .bottom-section {
                            padding: 0 1em 0 1em;
                        }
                    }
                }
            }
        }
    }

    .p-datatable {
        table {
            tr:nth-child(even) {
                background-color: #e6e6e6;
            }

            td,
            th {
                border: none;
                background-color: inherit;
                display: flex;
                flex-basis: 0;
            }

            td span {
                text-align: right;
                display: inline-block;
                flex-grow: 1;

                &.p-column-title {
                    text-align: left;
                }
            }

            th:first-child,
            td:first-child {
                border: none;
            }

            th:last-child,
            td:last-child {
                border: none;
            }
        }
    }
}

@media (max-width: 640px) {
    body .layout-wrapper .layout-main .layout-content .model-list .top-section .model-title {
        padding: 1.5em 1em 0.25em 1em;
    }

    body {
        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding: 1em 0 1.5em 0;
        }
    }

    .p-dialog {
        width: 100vw !important;
    }

    .md-hidden {
        display: none;
    }
}

@media (max-width: 600px) {
    .sm-hidden {
        display: none;
    }

    body {
        .p-dialog.gestor-dialog {
            width: 100vw;
        }
    }
}

@media (max-width: 480px) {
    .xs-hidden {
        display: none;
    }
}



